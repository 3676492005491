import * as React from "react";
import sanitizeHtml from "sanitize-html";

import { Layout, MainLogo } from "../components/layout";
import { Image } from "../components/image";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { GiHand } from "react-icons/gi";
import { GrGroup } from "react-icons/gr";
import { MdGppGood, MdOutlineEmail, MdPermContactCalendar } from "react-icons/md";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const pillars = [
  {
    icon: GiHand,
    title: "Friendly & Interactive",
    text:
      "Plant Army members are extremely friendly and regularly interact with their viewers and communities. You will always feel warm, welcomed, and loved in a Plant Army stream!"
  },
  {
    icon: RiLightbulbFlashFill,
    title: "Positive Energy",
    text:
      "Plant Army members are all about positive energy! Life isn’t perfect, but we believe it is most constructive to find and create good wherever and whenever possible. Grow at your own pace."
  },
  {
    icon: GrGroup,
    title: "Proactive Inclusivity",
    text:
      "Plant Army members believe in intersectionality and ensure that people of all identities feel welcomed and uplifted. This means actively educating ourselves and keeping each other accountable."
  },
  {
    icon: MdGppGood,
    title: "Community Safety",
    text:
      "Plant Army members understand the value of reducing the impact of intolerance and abuse as early as possible by cutting out bigotry and other harmful behavior to protect their inner and extended communities."
  }
];

const charity = [
  {
    year: "2019",
    name: "Trevor Project",
    raised: "$14,291.46",
    link: "https://tiltify.com/+plant-army/plant-army-pride-month-2019",
    logo: "../images/Trevor-Project-Logo.svg"
  },
  {
    year: "2020",
    name: "St. Jude",
    raised: "$23,290.34",
    link: "https://tiltify.com/+plant-army/plant-army-for-st-jude-2020",
    logo: "../images/StJude-Logo.svg"
  },
  {
    year: "2020",
    name: "Trevor Project",
    raised: "$67,676.70",
    link: "https://tiltify.com/+plant-army/plant-army-pride-2020",
    logo: "../images/Trevor-Project-Logo.svg"
  },
  {
    year: "2020",
    name: "Save the Children",
    raised: "$7,491.88",
    link: "https://tiltify.com/+plant-army/for-the-kids-2020",
    logo: "../images/Save-The-Children-Logo.svg"
  },
  {
    year: "2021",
    name: "OutRight Action International",
    raised: "$17,013.69",
    link: "https://tiltify.com/+plant-army/plant-army-pride-2021",
    logo: "../images/Outright-Intl-Logo.svg"
  },
  {
    year: "2021",
    name: "Gamers Outreach Foundation",
    raised: "$721.66",
    link: "https://tiltify.com/+plant-army/plant-army-for-gamers-outreach-2021",
    logo: "../images/Gamers-Outreach-Logo.png"
  }
];

const faq = [
  {
    id: "q1",
    question: "How often do you open applications?",
    answer:
      "Currently we are restructuring our team to be a more close knit, fun space. We hope to open applications approximately quarterly for a short window each quarter, only accepting a few new sprouts."
  },
  {
    id: "q2",
    question: "What do you look for in applications?",
    answer:
      "We take a holistic look at all of your social media, as well as your streams. We want to make sure our team members are streamers who are friendly, interactive, positive, inclusive and safety focused.\n We want to make sure that the applicants we accept embrace and embody our values of diversity, inclusion and safety."
  },
  {
    id: "q3",
    question: "Who reviews my application?",
    answer: "The Team Leaders review and discuss all applications."
  },
  {
    id: "q4",
    question: "Why wasn't I accepted on the team after applying?",
    answer:
      "We wish we could accept everyone to the team, because there are so many wonderful, unique creators who apply. \n The team went through a period of accepting a <strong>lot</strong> of new sprouts, and as a result we haven’t been  the team we’d ideally want to be. Because of this, we’ve learned a bit and moved toward taking only a few people at a time so everyone feels welcomed in and finds their place on the team, rather than feeling lost in the shuffle of a team that’s too big. \n Definitely do not give up, we’re sure your stream is so lovely and so are you so please apply again in the future!"
  },
  {
    id: "q5",
    question: "How many members are currently on the Plant Army team?",
    answer: "Currently, about 95 members."
  },
  {
    id: "q6",
    question: "What is the safety policy and why do you have it?",
    answer:
      "The Plant Army is a place where bigotry, racism, homophobia, transphobia, harassment, abuse, and predatory behavior are categorically unwelcome. \n Team members must actively work to keep their community – and therefore the wider Plant Army community – safe by not participating in this type of behavior and to remove anyone who does participate in this type of behavior from their communities. \n To inform us of a harmful user for investigation – including Plant Army streamers, non-PA streamers, community members, etc. – fill out our Report Form."
  },
  {
    id: "q7",
    question: "I have a concern about someone on the team. What do I do?",
    answer:
      "We take every concern extremely seriously, and are currently fixing our report form. Until it is available again, please reach out to a team leader and we will be in touch with you."
  }
];

const leaders = [
  {
    name: "Jenn",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "jenn@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@jennroguetv"
      }
    ]
  },
  {
    name: "Kyra",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "kyra@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@unkyra1"
      }
    ]
  },
  {
    name: "Anne",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "anne@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@annedorko"
      }
    ]
  },
  {
    name: "Plat",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "plat@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@PlatinumRhythm"
      }
    ]
  },
  {
    name: "Tony",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "triggatones@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@TriggaTones"
      }
    ]
  },
  {
    name: "Tapgirl",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "tapgirl301@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@tapgirl301"
      }
    ]
  },
  {
    name: "Glimjii",
    contact: [
      {
        icon: MdOutlineEmail,
        info: "glimjii@plant.army"
      },
      {
        icon: FaTwitter,
        info: "@Glimjii"
      }
    ]
  }
];

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Plant Army: Twitch Stream Team" />
      <div>
        <div className="pt-20 pb-8 lg:pt-32 lg:pb-24 bg-gradient-to-br to-yellow-500 from-pink-700 text-white">
          <div className="container max-w-3xl	mx-auto">
            <div className="flex flex-wrap lg:flex-nowrap justify-center justify-items-center content-center items-center">
              <div className="lg:flex-none">
                <MainLogo />
              </div>
              <div className="text-center lg:text-left lg:flex-1 lg:pl-12 p-6 max-w-lg">
                <p className="font-serif text-3xl lg:text-4xl font-semibold leading-none lg:leading-none pb-2">
                  Working to put good into the world.
                </p>
                <p className="text-sm lg:text-base">
                  The <span className="argyle">Plant Army</span> is a Twitch
                  stream team of friendly, interactive, inclusive streamers who
                  are working to put good into the world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container max-w-3xl	mx-auto py-10">
          <h2 className="font-serif font-bold text-center text-xl lg:text-4xl mb-6">
            Team Pillars & Values
          </h2>
          <div className="container mx-auto flex flex-wrap justify-between p-4 lg:p-0">
            {pillars.map(item => {
              const SetIcon = item.icon;
              return (
                <div
                  key={item.title}
                  className="w-full lg:w-[49%] drop-shadow-md bg-white rounded mb-4"
                >
                  <h3 className="font-bold mb-2 leading-none p-3 bg-indigo-900 text-white rounded-t">
                    <SetIcon
                      className="icon-white inline-block mt-[-4px] mr-2 p-0"
                      style={{ style: { color: "#FFFFFF" } }}
                    />{" "}
                    {item.title}
                  </h3>
                  <p className="text-sm p-3 pt-0">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="bg-gradient-to-tr to-yellow-500 from-pink-700">
          <div className="container max-w-3xl	mx-auto py-10 px-4 lg:px-0">
            <h2 className="font-serif font-bold text-center text-2xl lg:text-4xl mb-6 text-white">
              Charitable Work
            </h2>
            {charity.reverse().map(item => {
              return (
                <div
                  key={item.link}
                  className="w-full drop-shadow-md bg-white rounded mb-4 flex lg:flex-nowrap flex-wrap"
                >
                  <div className="flex-none p-4 bg-indigo-900 text-white w-1/2 lg:w-24 text-center flex justify-center justify-items-center content-center items-center rounded-tl lg:rounded-l">
                    <span>{item.year}</span>
                  </div>
                  <div className="flex-none p-4 bg-zinc-900 text-white w-1/2 lg:w-32 text-center flex justify-center justify-items-center content-center items-center text-sm rounded-tr lg:rounded-none">
                    <span>{item.raised}</span>
                  </div>
                  <div className="flex-none pl-4 lg:p-4 w-1/4 lg:w-32 text-center flex justify-center justify-items-center content-center items-center text-sm rounded-tr lg:rounded-none">
                    <Image
                      filename={item.logo}
                      alt={item.name}
                      classes="w-24 lg:w-32"
                    />
                  </div>
                  <div className="flex-1 px-4 py-2 flex justify-items-center content-center items-center">
                    <div>
                      <h3 className="lg:text-xl pb-2">{item.name}</h3>
                      <div className="text-sm">
                        <a href={item.link}>
                          <StaticImage
                            src="../images/Tiltify-Logo.png"
                            alt="Tiltify Logo"
                            width={25}
                            height={25}
                          />
                          <span className="pl-2 text-xs lg:text-base">
                            View Tiltify Campaign
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="container max-w-3xl	mx-auto py-10 px-4 lg:px-0">
          <h2 className="font-serif font-bold text-center text-2xl lg:text-4xl mb-8">
            Frequently Asked Questions
          </h2>
          {faq.map(item => {
            const options = {
              allowedTags: ["br", "span", "p", "strong"]
            };
            const answerArray = item.answer.split("\n");
            var answerHTML = null;
            if (answerArray.length > 1) {
              var answerList = [];
              answerArray.forEach(item => {
                const sanitize = sanitizeHtml(item, options);
                answerList.push('<p class="pb-2">' + sanitize + "</p>");
                answerHTML = answerList.join("");
              });
            } else {
              const sanitize = sanitizeHtml(item.answer, options);
              answerHTML = '<p class="pb-2">' + sanitize + "</p>";
            }

            return (
              <div
                key={item.question}
                className="bg-white rounded mb-4 w-full drop-shadow-md"
              >
                <h2 className="bg-pink-700 rounded-t text-white px-4 py-2 lg:p-4 text-xs lg:text-base">
                  {item.question}
                </h2>
                <div
                  className="px-4 py-2 lg:p-4 text-sm lg:text-base"
                  dangerouslySetInnerHTML={{
                    __html: answerHTML
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-gradient-to-tr to-yellow-500 from-pink-700">
        <div className="container max-w-3xl	mx-auto py-10 px-4 lg:px-0">
          <h2 className="font-serif font-bold text-center text-2xl lg:text-4xl mb-6 text-white">
            Contact Us
          </h2>
          <p className="text-center mb-6 text-white max-w-xl	mx-auto">
            Message @plantarmy on Twitter, email team@plant.army, or reach out
            to one of the team leaders below.
          </p>
          <h3 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6 text-white">
            Leadership Team
          </h3>
          <div className="container mx-auto flex flex-wrap justify-between">
            {leaders.map(leader => {
              return (
                <div
                  key={leader.name}
                  className="w-full lg:w-[49%] drop-shadow-md bg-white rounded mb-4"
                >
                  <h3 className="font-bold mb-2 leading-none p-3 bg-indigo-900 text-white rounded-t">
                    <MdPermContactCalendar className="icon-white inline-block mt-[-4px] mr-2 p-0" />
                    {leader.name}
                  </h3>
                  <div className="text-sm p-3 pt-0 break-words">
                    {leader.contact.map(contact => {
                      const SetIcon = contact.icon;
                      return (
                        <p key={contact.info}>
                          <SetIcon
                            className="icon-white inline-block mt-[-4px] mr-2 p-0"
                            style={{ style: { color: "#FFFFFF" } }}
                          />{" "}
                          {contact.info}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
